import { Link, graphql } from "gatsby";
import React, { FC, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { twMerge } from "tailwind-merge";
import Layout from "../../components/common/Layout";
import { Chapter, IStartGuidePage } from "../../types/start-guide";
import { componentsBuilder } from "../../utils/renderer";

const StartGuide: FC<IStartGuidePage> = ({ data }) => {
  const startGuideDetails = data?.allStrapiStartGuide?.nodes[0];
  const [activeChapter, setActiveChapter] = useState<number>(0);
  const activeChapterData = startGuideDetails.Chapter[activeChapter];
  console.log(startGuideDetails.title);
  return (
    <Layout>
      <div className="px-4 xl:px-10 pt-[30px] uppercase text-xs font-semibold leading-[14px] text-dsc-softgray flex items-center gap-x-4">
        <Link
          className="transition-all duration-300 hover:text-dsc-darkshade"
          to="/tp/smartsheet"
        >
          （スマートシート）
        </Link>
        <span className="text-lg font-medium"> &gt;</span>
        <span className="text-dsc-darkshade"> スタートガイド</span>
      </div>
      <div className="pt-[30px] pb-[72px] px-4 xl:px-10">
        <h1 className="text-center text-4xl mb-14 leading-[50px] text-dsc-title font-bold">
          {startGuideDetails.title}
        </h1>
        <div
          className="p-[10px] flex rounded-sm"
          style={{ backgroundColor: startGuideDetails.bg_color }}
        >
          <div className="w-[350px] hidden md:block">
            {startGuideDetails.Chapter.map(
              (chapter: Chapter, index: number) => (
                <button
                  className={twMerge(
                    `px-[25px] py-5 w-full rounded-tl-md rounded-bl-md text-sm text-left ${
                      activeChapter === index && "bg-white"
                    }`
                  )}
                  onClick={() => setActiveChapter(index)}
                  style={{ color: startGuideDetails.title_color }}
                >
                  {chapter.chapter_title}
                </button>
              )
            )}
          </div>
          <div className="w-full bg-white rounded-tr-md rounded-br-md prose-ul:list-disc prose-ol:list-decimal prose-ul:list-outside prose-ol:list-outside prose-ul:pl-8 prose-li:text-base prose-li:leading-7 prose-headings:text-dsc-title text-dsc-body prose-headings:font-bold prose-ul:mt-4 prose-ol:mb-6 prose-headings:my-4 prose-img:my-6 [&_.gatsby-image-wrapper]:my-4 [&_.gatsby-image-wrapper]:mb-6 prose-h2:text-[28px] prose-h2:leading-10 prose-h3:text-lg prose-h3:leading-6 prose-p:text-base prose-p:leading-7 prose-a:text-dsc-link [&_a:hover]:opacity-70 [&_a:hover]:transition-all [&_a:hover]:duration-300">
            {startGuideDetails.Chapter.map((chapter: Chapter, index) => (
              <>
                <button
                  className="w-full text-left px-3 py-2 font-bold text-sm block md:hidden"
                  style={{ color: startGuideDetails.title_color }}
                  onClick={() => setActiveChapter(index)}
                >
                  {chapter.chapter_title}
                </button>
                {index == activeChapter && (
                  <div className="p-5 pt-0">
                    <h2 className="mb-5">{activeChapterData.chapter_title}</h2>
                    <ReactMarkdown
                      components={componentsBuilder(
                        activeChapterData.chapter_body.medias
                      )}
                      children={
                        activeChapterData.chapter_body?.data?.chapter_body
                      }
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StartGuide;

export const query = graphql`
  query FetchStartGuideDetails($pageId: String!) {
    allStrapiStartGuide(filter: { slug: { eq: $pageId } }) {
      nodes {
        slug
        title_color
        title
        bg_color
        Chapter {
          chapter_title
          chapter_body {
            data {
              chapter_body
            }
            medias {
              src
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                }
              }
              file {
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;
