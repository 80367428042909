import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { extractInternalLink } from "./helpers";

export const componentsBuilder = (medias) => {
  return {
    a: ({ node, ...props }) => {
      const href = node.properties.href;
      const isInternal =
        href.includes("https://www.digitalstacks.net/") ||
        href.includes("https://digitalstacks.net/");
      return isInternal ? (
        <Link to={extractInternalLink(href)}>{props.children}</Link>
      ) : (
        <a {...props} target="_blank" rel="noreferrer" />
      );
    },

    p: ({ node, ...props }) => {
      try {
        // This function is perform to avoide <p><div></div></p> div tag inside p
        const imgList = node.children.filter(
          (node) => node.type === "element" && node.tagName === "img"
        );
        if (imgList.length > 0) {
          return <div {...props}></div>;
        }
      } catch (error) {
        console.log(error);
      }
      return <p {...props}></p>;
    },

    img: ({ src }) => {
      const target = medias.filter((media) => media.src == src)[0];
      const image = target?.localFile?.childImageSharp?.gatsbyImageData;
      return image !== undefined ? (
        <GatsbyImage
          image={image}
          alt={target?.file?.alternativeText}
          className="my-4 first:mt-0"
        />
      ) : (
        <img
          loading="lazy"
          src={`https://strapi-admin.digitalstacks.net${src}`}
          alt={target?.file?.alternativeText}
        />
      );
    },
  };
};
